import React, { useState } from 'react';
import './auth-styles.css';
import { Navigate } from 'react-router-dom';
import Spline from '@splinetool/react-spline';

export const Login = () => {
  //Estado para controlar que panel se está mostrando  
  const [isSignUp, setIsSignUp] = useState(false);
  // Guardar en localStorage el inicio de sesión
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  //Estado para inicio de sesión
  const [isLogged, setIsLogged] = useState(false);
  //Mensaje de error
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeErrorRegister, setMensajeErrorRegister] = useState('');

  //Manejador para cambiar el panel según si se pulsa registrarse o iniciar sesión
  const togglePanel = () => {
    setIsSignUp(!isSignUp);
  }

  //Manejador para cuando un usuario inicia sesión al pulsar iniciar sesión
  const handleLogin = async (e) => {
    e.preventDefault();
  
    // Obtener los valores del formulario
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
  
    // Validación de los datos
    if (!email || !password) {
      setMensajeError('Por favor, completa todos los campos.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMensajeError('Por favor, introduce un correo electrónico válido.');
      return;
    }

    // Realizar la solicitud al servidor para iniciar sesión
    try {
      const response = await fetch('https://coinmanager.es/coinmanager/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });
  
      const data = await response.json();

      if (response.ok) {
        // Si la solicitud es exitosa, verificar si el inicio de sesión fue exitoso
        if (data.success) {
          // Guardar datos del usuario en localStorage
          localStorage.setItem('userId', data.userId);
          localStorage.setItem('name', data.name);
          localStorage.setItem('isLoggedIn', true);
          setIsLogged(true);
        } else {
            // Manejar el caso cuando las credenciales son incorrectas
            setMensajeError('Las credenciales proporcionadas son incorrectas. Por favor, verifica tu email y contraseña.');
        }
      } else {
        // Si la solicitud no es exitosa, mostrar un mensaje de error
        setMensajeError('Se ha producido un error al intentar iniciar sesión. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
        setMensajeError('Se ha producido un error. Por favor, inténtalo de nuevo más tarde o contacta con el soporte técnico.');
    }
  }

  //Manejador para registrar un usuario
  const handleRegister = async (e) => {
    e.preventDefault();
    
    // Obtener los valores del formulario de registro
    const username = e.target.elements.username.value.trim();
    const email = e.target.elements.email.value.trim();
    const password = e.target.elements.password.value.trim();

    // Validación de los datos
    if (!username || !email || !password) {
      setMensajeErrorRegister('Por favor, completa todos los campos.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMensajeErrorRegister('Por favor, introduce un correo electrónico válido.');
      return;
    }

    const minPasswordLength = 8;
    if (password.length < minPasswordLength) {
      setMensajeErrorRegister('La contraseña debe tener al menos 8 caracteres.');
      return;
    }
  
    // Crear un objeto con los datos del usuario
    const userData = {
      username: username,
      email: email,
      password: password
    };
  
    try {
      // Realizar la solicitud POST al servidor con los datos del usuario
      const response = await fetch('https://coinmanager.es/coinmanager/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });
  
      // Convertir la respuesta a formato JSON
      const data = await response.json();
  
      // Verificar si el registro fue exitoso
      if (data.success) {
        // Registro exitoso, redirigir al usuario a la página de inicio de sesión u otra página
        setMensajeErrorRegister('¡Registro exitoso! Por favor, inicia sesión para continuar.');
        // Redirigir al usuario a la página deseada
        <Navigate to="/" />;
        // Aquí podrías redirigir al usuario a la página de inicio de sesión
      } else {
        // Mostrar mensaje de error al usuario
        setMensajeErrorRegister(data.message);
      }
    } catch (error) {
        setMensajeErrorRegister('Se ha producido un error al intentar registrarse. Por favor, inténtalo de nuevo más tarde.');
    }
  };
  

  //Si el estado de logeado es true redirigimos a la página de portafolio
  if (isLogged || isLoggedIn) {
    return <Navigate to="/portafolio" />;
  }

  return (
    <body className='login-body hidden' id='login-body'>
      <section>
        <div className={`container ${isSignUp ? 'right-panel-active' : ''}`} id="container">
          <div className="form-container sign-up-container">
            <form onSubmit={handleRegister}>
              <h1>Registrarse</h1>
              <label>
                <input type="text" name="username" id="usuario" placeholder="Name" required maxLength="15" />
              </label>
              <label>
                <input type="email" name="email" id="register-email" placeholder="Email" required />
              </label>
              <label>
                <input type="password" name="password" id="register-password" placeholder="Password" required />
              </label>
              <button className='login-button' style={{ marginTop: 9 + 'px' }}>Registrarse</button>
              {mensajeErrorRegister && (
                <div className="error-message">
                  {mensajeErrorRegister}
                </div>
              )}
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form onSubmit={handleLogin}>
              <h1>Iniciar sesión</h1>
              <label>
                <input type="email" name="email" id="sign-in-email" placeholder="Email" required autoFocus />
              </label>
              <label>
                <input type="password" name="password" id="sign-in-password" placeholder="Password" required />
              </label>
              <button type="submit" className='login-button' style={{ marginTop: 9 + 'px' }}>Iniciar sesión</button>
              {mensajeError && (
                <div className="error-message">
                  {mensajeError}
                </div>
              )}
            </form>           
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Iniciar sesión</h1>
                <p className='login-paragraph'><span id='login-span'>Inicia sesión</span> si ya tienes una cuenta con nosotros</p>
                <button className="mt-5 login-button" id="signIn" onClick={togglePanel}>Iniciar sesión</button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Crear una cuenta</h1>
                <p className='login-paragraph'>¡Crea una cuenta con nosotros y <span id='login-span'>gestiona tus criptomonedas</span> de la manera más sencilla!</p>
                <button className='login-button' id="signUp" onClick={togglePanel}>Registrarse</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </body>
  )
}