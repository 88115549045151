import React from 'react';
import './login-logout-button.css';

export const LoginButton = () => {

  return (
    <a href="#login-body">
      <button  id='login-button'>Iniciar Sesión</button>
    </a>
  )
}
