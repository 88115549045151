import React, { useState, useEffect } from 'react';

const SessionTimer = ({ expirationTime, onExpire }) => {
  useEffect(() => {
    let timerId = null;

    const resetTimer = () => {
      clearTimeout(timerId);
      timerId = setTimeout(onExpire, expirationTime);
    };

    const eventListeners = ['mousemove', 'keydown'].map(event => {
      return { eventName: event, listener: resetTimer };
    });

    eventListeners.forEach(({ eventName, listener }) => {
      window.addEventListener(eventName, listener);
    });

    resetTimer(); // Inicializar el temporizador

    return () => {
      clearTimeout(timerId);
      eventListeners.forEach(({ eventName, listener }) => {
        window.removeEventListener(eventName, listener);
      });
    };
  }, [expirationTime, onExpire]);

  return null;
};

export default SessionTimer;
