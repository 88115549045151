import './App.css';
import { RouterPrincipal } from './Routers/RouterPrincipal';

function App() {

  return (  
    <div className='App'>    
        <RouterPrincipal/>    
    </div>  
  );
}

export default App;
