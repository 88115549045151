import React from 'react';
import './footer-styles.css';

export const Footer = () => {
  return (
    <>
        <div className="footer1">
            <p>Copyright &copy; 2024 CoinManager</p>
        </div>     
    </>
  )
}
