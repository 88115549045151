import React from 'react'
import './login-logout-button.css';
import { MdLogout } from "react-icons/md";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const LogoutButton = () => {

  const navigate = useNavigate();

  const handleLogout = async () => {
      try {
        // Eliminar el estado de inicio de sesión
        localStorage.removeItem('isLoggedIn');
        // Redirigir a la página de inicio de sesión
        navigate('/');
      } catch (error) {
        console.error('Error al cerrar sesión:', error);
        // Manejar cualquier error que pueda ocurrir al cerrar la sesión
      }
  };

  return (
    <>
      <hr/>
      <button id='logout-button' onClick={handleLogout}>
        <MdLogout id='logout-icon'/>
        Cerrar Sesión
      </button>
    </>
  )
}
